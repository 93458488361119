/* Layouts */

.navbar {
    &.fixed-top {
        + .page-body-wrapper {
            padding-top: $navbar-height;
        }
    }
}

// Sidebar Mini
.sidebar-mini {
    @media (min-width: 992px) {
        .navbar {
            .navbar-brand-wrapper {
                width: $sidebar-width-mini;
            }
            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-mini});
            }
        }
        .sidebar {
            width: $sidebar-width-mini;
            .nav {
                .nav-item {
                    padding: 0;
                    margin: 0;
                    .nav-link {
                        @include display-flex;
                        @include align-items(center);
                        @include justify-content(center);
                        @include flex-direction(column-reverse);
                        text-align: center;
                        position: relative;
                        border-bottom: none;
                        .menu-title {
                            display: block;
                            margin: auto;
                        }
                        .menu-sub-title {
                            margin: auto;
                        }
                        .badge {
                            margin-left: 5px;
                            display: none;
                        }
                        i {
                            &.menu-icon {
                                display: block;
                                margin-right: auto;
                                margin-left: auto;
                            }
                            &.menu-arrow {
                                display: inline-block;
                                margin-left: 5px;
                                position: absolute;
                                top: 50%;
                                right: 10px;
                                @include transform(translateY(-50%));
                                &:before {
                                    content: "\F140";
                                }
                            }
                        }
                        &[aria-expanded="true"] {
                            .menu-arrow {
                                &:before {
                                    content: "\f143";
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-panel {
            width: calc(100% - #{$sidebar-width-mini});
        }
        &:not(.sidebar-icon-only) {
            //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
            .sidebar {
                .nav {
                    &:not(.sub-menu) {
                        > .nav-item {
                            border-bottom: 1px solid
                                rgba($sidebar-menu-color, 0.2);
                            .nav-link {
                                height: auto;
                                padding: $sidebar-mini-menu-padding;
                                i {
                                    &.menu-icon {
                                        margin-bottom: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                    &.sub-menu {
                        padding: 0;
                        border-top: none;
                        .nav-item {
                            .nav-link {
                                padding: 7px 0 7px 25px;
                                margin-left: auto;
                                margin-right: auto;
                                display: block;
                                text-align: left;
                                width: 66%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Sidebar Icon Only
.sidebar-icon-only {
    @media (min-width: 992px) {
        .navbar {
            .navbar-brand-wrapper {
                width: $sidebar-width-icon;
                .brand-logo {
                    display: none;
                }
                .brand-logo-mini {
                    display: none;
                }
            }
            .navbar-menu-wrapper {
                width: calc(100% - #{$sidebar-width-icon});
            }
        }
        .sidebar {
            width: $sidebar-width-icon;
            .nav {
                overflow: visible;
                .nav-item {
                    position: relative;
                    padding: 0;
                    .nav-link {
                        display: block;
                        text-align: center;
                        .menu-title,
                        .badge,
                        .menu-sub-title {
                            display: none;
                        }
                        .menu-title {
                            @include border-radius(0 5px 5px 0px);
                            @at-root #{selector-append(".rtl", &)} {
                                @include border-radius(5px 0 0 5px);
                            }
                        }
                        i {
                            &.menu-icon {
                                margin-right: 0;
                                margin-left: 0;
                            }
                            &.menu-arrow {
                                display: none;
                            }
                        }
                        &[aria-expanded] {
                            .menu-title {
                                @include border-radius(0 5px 0 0px);
                                @at-root #{selector-append(".rtl", &)} {
                                    @include border-radius(5px 0 0 0);
                                }
                            }
                        }
                    }
                    &.nav-profile {
                        display: none;
                    }
                    &.nav-category {
                        display: none;
                    }
                    &.nav-doc {
                        margin: 0;
                        i {
                            display: block;
                        }
                    }
                    .collapse {
                        display: none;
                    }
                    &.hover-open {
                        .nav-link {
                            .menu-title {
                                @include display-flex;
                                @include align-items(center);
                                background: $sidebar-menu-active-bg;
                                padding: 0.5rem 1.4rem;
                                left: $sidebar-width-icon;
                                position: absolute;
                                text-align: left;
                                top: 0;
                                bottom: 0;
                                width: $icon-only-collapse-width;
                                z-index: 1;
                                line-height: 1.8;
                                @at-root #{selector-append(".rtl", &)} {
                                    left: auto;
                                    right: $sidebar-width-icon;
                                    text-align: left;
                                }
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        .collapse,
                        .collapsing {
                            display: block;
                            padding: 0.5rem 0;
                            background: $sidebar-bg;
                            @include border-radius(0 0 5px 0);
                            position: absolute;
                            left: $sidebar-width-icon;
                            width: $icon-only-collapse-width;
                            @at-root #{selector-append(".rtl", &)} {
                                left: auto;
                                right: $sidebar-width-icon;
                                @include border-radius(0 0 0 5px);
                            }
                        }
                    }
                }
                &.sub-menu {
                    padding: $sidebar-icon-only-submenu-padding;
                    .nav-item {
                        .nav-link {
                            text-align: left;
                            padding-left: 20px;
                        }
                    }
                    @at-root #{selector-append(".rtl", &)} {
                        .nav-item {
                            margin-right: auto;
                            margin-left: 0;
                            .nav-link {
                                text-align: right;
                                &:before {
                                    left: 0;
                                    right: unset;
                                }
                            }
                        }
                    }
                }
            }
            .sidebar-actions {
                display: none;
            }
        }

        .main-panel {
            width: calc(100% - #{$sidebar-width-icon});
        }
    }
}
