/* Badges */

.badge {
  border-radius: .125rem;
  font-size: 11px;
  line-height: 1;
  padding: .375rem .5625rem;
  font-family: $type-1;
  font-weight: 500;
  &.badge-pill {
    border-radius: 10rem; 
  }
}


/*Badge variations*/
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variations($value);
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}
/*Badge inverse variations*/

@each $color,
$value in $theme-colors {
  .badge-inverse-#{$color} {
    @include badge-inverse-variations($value);
  }
}