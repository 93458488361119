/* Dropdowns */

.dropdown {
    .dropdown-toggle {
        &:after {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            font-family: "simple-line-icons";
            font-size: 14px;
            content: "\e604";
            width: auto;
            height: auto;
            vertical-align: baseline;
            font-size: 0.75rem;
        }
    }
    .dropdown-menu {
        margin-top: 0.75rem;
        font-size: $default-font-size;
        box-shadow: $dropdown-menu-box-shadow;
        .dropdown-item {
            font-size: 1rem;
            padding: 0.25rem 1.5rem;
            &:active {
                background: initial;
            }
        }
    }
}
