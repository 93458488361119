/* Sidebar */

.sidebar {
    min-height: calc(100vh - #{$navbar-height});
    background: $sidebar-bg;
    font-family: $type-1;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 9999;
    transition: width $action-transition-duration
            $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration
            $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -moz-transition: width $action-transition-duration
            $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;
    -ms-transition: width $action-transition-duration
            $action-transition-timing-function,
        background $action-transition-duration
            $action-transition-timing-function;

    .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 60px;

        .nav-item {
            padding: 0 $sidebar-menu-padding-x;
            @include transition-duration(0.25s);
            transition-property: background;
            -webkit-transition-property: background;
            .collapse {
                z-index: 999;
            }

            .nav-link {
                @include display-flex;
                @include align-items(center);
                white-space: nowrap;
                padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
                color: $sidebar-menu-color;
                @include transition-duration(0.45s);
                transition-property: color;
                -webkit-transition-property: color;

                :not(.sub-menu) & {
                    border-top: 1px solid $sidebar-nav-link-border-color;
                }

                i {
                    color: inherit;

                    &.menu-icon {
                        font-size: $sidebar-icon-font-size;
                        line-height: 1;
                        margin-left: auto;
                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                        color: $sidebar-menu-icon-color;
                        &:before {
                            vertical-align: middle;
                        }
                    }
                }

                .menu-title {
                    color: inherit;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                }

                .badge {
                    margin-right: auto;
                    margin-left: 1rem;
                }

                &[aria-expanded="true"] {
                    .menu-arrow {
                        &:before {
                            content: "\f140";
                        }
                    }
                }
            }

            &.active:not(.navbar-brand-mini-wrapper) {
                background: $sidebar-menu-active-bg;
                > .nav-link {
                    border-top: none;
                    .menu-title {
                        color: $sidebar-menu-active-color;
                        font-family: $type-1;
                        font-weight: $font-weight-medium;
                    }
                    i {
                        color: theme-color(success);
                    }
                }

                & + .nav-item {
                    .nav-link {
                        border-top: none;
                    }
                }
            }
            &:not(.nav-category):not(.nav-profile):hover {
                background: $sidebar-menu-hover-bg;
            }
            &.nav-profile {
                max-width: 270px;
                margin-top: 10px;
                margin-bottom: 10px;

                .nav-link {
                    display: flex;
                    border-top: none;
                    padding: 10px 0;

                    .profile-image {
                        margin-right: 15px;
                        position: relative;

                        .rtl & {
                            margin-right: 0;
                            margin-left: 15px;
                        }
                    }

                    .dot-indicator {
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        transform: translate(50%, -50%);
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;

                        .rtl & {
                            left: 0;
                            right: auto;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .profile-name {
                        margin-bottom: 5px;
                        font-weight: 500;
                        font-size: 15px;
                    }

                    .designation {
                        margin-bottom: 0;
                        font-weight: 400;
                        color: $text-muted;
                        font-size: 12px;
                    }

                    .icon-container {
                        font-size: 16px;
                        position: relative;
                        color: $sidebar-menu-icon-color;
                        margin-left: auto;
                        align-self: flex-start;

                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }

                        .sidebar-mini & {
                            margin-right: auto;
                        }

                        .dot-indicator {
                            top: 0;
                            transform: none;
                            color: $sidebar-menu-icon-color;
                        }
                    }
                }
            }

            &.nav-category {
                color: $sidebar-nav-category-color;
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;

                .nav-link {
                    border: none;
                }

                & ~ .nav-category {
                    .nav-link {
                        border-top: 1px solid $sidebar-nav-link-border-color;
                    }
                }

                .nav-link {
                    color: inherit;
                    padding: $sidebar-nav-category-padding;
                }
            }
            &.pro-upgrade {
                &:hover {
                    background-color: transparent;
                }
            }

            &.navbar-brand-mini-wrapper {
                display: none;

                .nav-link {
                    width: 100%;

                    .sidebar-icon-only & {
                        margin: 0;
                    }
                }

                .sidebar-icon-only & {
                    @media (min-width: 992px) {
                        display: block;
                    }
                }
            }
        }

        &:not(.sub-menu) {
            > .nav-item {
                &:hover {
                    &:not(.nav-category):not(.nav-profile) {
                        > .nav-link {
                            color: $sidebar-menu-hover-color;
                        }
                    }
                }
            }
        }
        &.sub-menu {
            margin-bottom: 20px;
            margin-top: 0;
            list-style: none;

            .nav-item {
                padding: 0;
                .nav-link {
                    color: $sidebar-submenu-color;
                    padding: $sidebar-submenu-item-padding;
                    position: relative;
                    font-size: $sidebar-submenu-font-size;
                    line-height: 1;
                    height: auto;
                    border-top: 0;
                    &:before {
                        content: "\e606";
                        font-family: "simple-line-icons";
                        display: block;
                        position: absolute;
                        left: 0px;
                        .rtl & {
                            content: "\e605";
                            left: auto;
                            right: 0;
                        }
                        top: 50%;
                        @include transform(translateY(-50%));
                        color: lighten($sidebar-submenu-color, 10%);
                        font-size: 0.75rem;
                    }
                    &.active {
                        color: $sidebar-menu-active-color;
                        background: transparent;
                    }
                    &:hover {
                        color: $sidebar-submenu-hover-color;
                    }
                }
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}
//sidebar color variation

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        right: -$sidebar-width-lg;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        &.active {
            right: 0;
        }
    }
}
