// Functions

@function social-color($key: "twitter") {
  @return map-get($social-colors, $key);
}

@each $color, $value in $social-colors {
  .bg-#{$color} {
    background: social-color($color);
  }
}