/* Navbar */

.navbar {
    font-family: $type-1;
    font-weight: $font-weight-light;
    background: $navbar-bg;
    transition: background $action-transition-duration
        $action-transition-timing-function;
    -webkit-transition: background $action-transition-duration
        $action-transition-timing-function;
    -moz-transition: background $action-transition-duration
        $action-transition-timing-function;
    -ms-transition: background $action-transition-duration
        $action-transition-timing-function;

    .navbar-brand {
        width: 110px;
    }
    .navbar-brand-wrapper {
        transition: width $action-transition-duration
                $action-transition-timing-function,
            background $action-transition-duration
                $action-transition-timing-function;
        -webkit-transition: width $action-transition-duration
                $action-transition-timing-function,
            background $action-transition-duration
                $action-transition-timing-function;
        -moz-transition: width $action-transition-duration
                $action-transition-timing-function,
            background $action-transition-duration
                $action-transition-timing-function;
        -ms-transition: width $action-transition-duration
                $action-transition-timing-function,
            background $action-transition-duration
                $action-transition-timing-function;
        background: $sidebar-bg;
        width: $sidebar-width-lg;
        height: $navbar-height;
        padding: 0 $sidebar-menu-padding-x;
        .sidebar-icon-only & {
            padding: 0;
        }
        @media (max-width: 991px) {
            width: 55px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .navbar-brand {
            color: lighten(color(gray-dark), 20%);
            font-size: 1.5rem;
            line-height: 48px;
            margin-right: 0;
            padding: 0.25rem 0;

            &:active,
            &:focus,
            &:hover {
                color: lighten(color(gray-dark), 10%);
            }

            img {
                width: calc(#{$sidebar-width-lg} - 120px);
                max-width: 100%;
                height: 28px;
                margin: auto;
                vertical-align: middle;
                &.logo-light {
                    display: none;
                    .sidebar-light & {
                        display: flex;
                    }
                }

                .sidebar-light & {
                    display: none;
                }
            }
            &.brand-logo-mini {
                display: none;
                @media screen and (max-width: 991px) {
                    display: flex;
                }
                img {
                    width: calc(#{$sidebar-width-icon} - 35px);
                    max-width: 100%;
                    height: 28px;
                    margin: auto;
                    display: inline;
                }
            }
        }
        .navbar-toggler {
            color: $sidebar-menu-icon-color;
            padding: 0;
            margin-left: auto;
            @media screen and (max-width: 991px) {
                display: none;
            }
            .rtl & {
                margin-left: 0;
                margin-right: auto;
            }
            .sidebar-icon-only & {
                margin-right: auto;
                margin-left: auto;
            }
            .sidebar-dark & {
                color: $sidebar-menu-icon-color;
            }
        }
    }

    .navbar-menu-wrapper {
        transition: width $action-transition-duration
            $action-transition-timing-function;
        -webkit-transition: width $action-transition-duration
            $action-transition-timing-function;
        -moz-transition: width $action-transition-duration
            $action-transition-timing-function;
        -ms-transition: width $action-transition-duration
            $action-transition-timing-function;
        color: $navbar-menu-color;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - #{$sidebar-width-lg});
        height: $navbar-height;
        box-shadow: 18px 1px 20px rgba(0, 0, 0, 0.1);
        @media (max-width: 991px) {
            width: auto;
            padding-left: 15px;
            padding-right: 15px;
            flex-grow: 1;
        }

        .navbar-nav-right {
            margin-left: auto;

            .rtl & {
                margin-left: 0;
                margin-right: auto;
            }
        }

        .navbar-toggler {
            border: 0;
            color: inherit;
            height: $navbar-height;
            @include border-radius(0px);
            padding-left: 5px;
            padding-right: 20px;
            &:not(.navbar-toggler-right) {
                font-size: 1.5rem;
                @media (max-width: 991px) {
                    display: none;
                }
            }
            &.navbar-toggler-right {
                @media (max-width: 991px) {
                    padding-left: 15px;
                    padding-right: 11px;
                    border-right: none;
                }
            }
        }
        .search-form {
            position: relative;
            margin-right: 15px;

            .rtl & {
                margin-right: 0;
                margin-left: 15px;
            }

            i {
                font-size: 20px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                color: $navbar-menu-icon-color;

                .rtl & {
                    left: auto;
                    right: 0;
                }
            }

            input {
                background-color: transparent;
                border: none;
                cursor: pointer;
                width: 0;
                height: 35px;
                padding: 0 0 0 20px;
                position: relative;
                transition: width 400ms ease, background 400ms ease;

                &:focus {
                    background-color: transparent;
                    border-bottom: 1px solid $navbar-menu-icon-color;
                    border-radius: 0;
                    padding-left: 30px;
                    cursor: text;
                    outline: 0;
                    width: 140px;

                    .rtl & {
                        padding-right: 30px;
                        padding-left: 0;
                    }
                }
            }
        }

        .count-indicator {
            position: relative;

            .count-symbol,
            .count-number {
                position: absolute;
                border-radius: 100%;
                border: 2px solid $white;
            }
            .count-symbol {
                top: 17px;
                right: -3px;
                width: 10px;
                height: 10px;
            }
            .count-number {
                min-width: 14px;
                height: 14px;
                font-size: 0.5rem;
                color: $white;
                bottom: 16px;
                right: -5px;
                line-height: 1;
                text-align: center;
            }
            &:after {
                display: none;
            }
        }
        .navbar-nav {
            flex-direction: row;
            align-items: center;
            .nav-item {
                .nav-link {
                    color: inherit;
                    font-size: $navbar-font-size;
                    margin-left: 15px;
                    margin-right: 15px;
                    height: $navbar-height / 2;
                    @include display-flex;
                    @include align-items(center);
                    @media (max-width: 767px) {
                        margin-left: 0.8rem;
                        margin-right: 0.8rem;
                    }
                    i {
                        font-size: $navbar-icon-font-size;
                        color: $navbar-menu-icon-color;
                    }
                }
                &.dropdown {
                    height: $navbar-height;
                    display: flex;
                    align-items: center;

                    .dropdown-toggle {
                        position: relative;
                        padding: 0 20px;

                        &:after {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 14px;
                            color: grey;

                            .rtl & {
                                right: auto;
                                left: 0;
                                margin: 0;
                            }
                        }
                    }
                    .dropdown-menu {
                        @extend .dropdownAnimation;
                        border: none;
                        @include border-radius(5px);
                        -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
                        -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
                        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
                        &.navbar-dropdown {
                            position: absolute;
                            font-size: 0.9rem;
                            margin-top: 0;
                            .rtl & {
                                right: auto;
                                left: 0;
                            }
                            padding: 0;

                            .dropdown-item {
                                @extend .d-flex;
                                @extend .align-items-center;
                                margin-bottom: 0;
                                padding: 11px 13px;
                                cursor: pointer;

                                i {
                                    font-size: 17px;
                                }

                                .ellipsis {
                                    max-width: 200px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .rtl & {
                                    i {
                                        @extend .mr-0;
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .dropdown-divider {
                                margin: 0;
                            }
                        }
                    }
                    @media (max-width: 991px) {
                        position: static;
                        .navbar-dropdown {
                            left: 20px;
                            right: 20px;
                            top: $navbar-height;
                            width: calc(100% - 40px);
                        }
                    }
                }
                &.user-dropdown {
                    position: relative;

                    .dropdown-menu {
                        min-width: 250px;
                        margin-top: 9px;

                        .dropdown-header {
                            padding: 20px;
                        }

                        .dropdown-item {
                            padding: 10px 15px;

                            .dropdown-item-icon {
                                margin-right: 15px;
                            }
                            .badge {
                                margin-left: 10px;
                            }
                        }
                    }

                    .dropdown-toggle {
                        img {
                            margin-right: 14px;
                        }
                    }
                }

                &.language-dropdown {
                    margin-left: 24px;
                    position: relative;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 24px;
                    }

                    .dropdown-menu {
                        width: 170px;
                        left: -15px !important;

                        .dropdown-item {
                            padding-left: 22px;
                            padding-right: 12px;

                            .rtl & {
                                padding-left: 12px;
                                padding-right: 22px;
                            }

                            i {
                                margin-right: 5px;

                                .rtl & {
                                    margin-right: 0;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                    .dropdown-toggle {
                        margin-right: 0;
                        margin-left: 0;
                        padding-left: 44px;
                        border-left: 1px solid #ecf0f4;

                        > div {
                            margin-right: 1rem;

                            .rtl & {
                                margin-left: 1rem;
                                margin-right: 0;
                            }
                        }

                        .rtl & {
                            padding-left: 20px;
                            padding-right: 44px;
                            border-left: none;
                            border-right: 1px solid #ecf0f4;
                        }
                    }

                    i {
                        font-size: 14px;
                    }
                }

                & .message-dropdown.count-indicator {
                    .count {
                        transform: translate(-50%);
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: theme-color(danger);
                        font-size: 6px;
                        color: $white;
                        text-align: center;
                        top: 4px;
                        left: 50%;
                    }
                }
            }
            &.navbar-nav-right {
                @media (min-width: 992px) {
                    margin-left: auto;
                    .rtl & {
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .navbar {
        flex-direction: row;
        .navbar-brand-wrapper {
            width: 75px;
            .navbar-brand {
                &.brand-logo {
                    display: none;
                }
                &.brand-logo-mini {
                    display: inline-block;
                }
            }
        }
    }

    .navbar-collapse {
        display: flex;
        margin-top: 0.5rem;
    }
}

@media (max-width: 480px) {
    .navbar {
        .navbar-brand-wrapper {
            width: 55px;
            .brand-logo-mini {
                padding-top: 0px;
            }
        }
    }
}

/* Navbar color variations */
.navbar {
    &.navbar-dark {
        .navbar-brand-wrapper {
            .navbar-toggler {
                border: none;
            }
        }
        .navbar-menu-wrapper {
            background: $sidebar-bg;
            color: $white;
            .nav-profile-text {
                p {
                    @extend .text-white;
                }
            }
            .search-form {
                input {
                    color: $white;
                }
            }
        }
    }
}
